import React from "react";
import styled from "styled-components";

import "../css/workStyle.css";

import Artisan from "./WorkStyles/Artisan";
import Partner from "./WorkStyles/Partner";
import Innovator from "./WorkStyles/Innovator";
import Producer from "./WorkStyles/Producer";
import Leader from "./WorkStyles/Leader";

import workStyleImage from "../images/workstyle.png";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  @media (max-width: 790px) {
    flex-direction: column;
  }
`;

const PictureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
  padding-right: 8rem;

  @media (max-width: 1080px) {
    padding-right: 1rem;
  }

  @media (max-width: 790px) {
    margin-bottom: 3em;
    padding-right: 0rem;
    align-items: center;
    justify-content: center;
  }
`;

const DefinitionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-left: 8rem;
  white-space: nowrap;

  @media (max-width: 1080px) {
    padding-left: 7rem;
  }

  @media (max-width: 900px) {
    padding-left: 4rem;
    white-space: normal;
  }

  @media (max-width: 850px) {
    padding-left: 1rem;
    white-space: normal;
  }

  @media (max-width: 790px) {
    padding-left: 0rem;
    white-space: normal;
  }
`;

const WorkStyleTitle = styled.p`
  font-size: 3rem;
  margin-bottom: 0.75em;
  font-style: italic;
  letter-spacing: 1px;
`;

const WorkStyleImage = styled.img`
  height: 30em;
  @media (max-width: 400px) {
    width: 17em;
    height: auto;
  }
`;

const WidgetTitle = styled.h1`
  color: rgba(255, 255, 255, 0.87);
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 700;
  margin: 1.5rem 0 3rem 0;
  font-family: "Raleway", sans-serif;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  letter-spacing: 2px;
  display: block;
  text-align: center;
  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 200px;
    padding-top: 0.35em;
    border-bottom: 3px solid white;
  }
`;

const WorkStyle = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <WidgetTitle>Workstyle</WidgetTitle>
        </div>
      </div>
      <div className="row">
        <ContentWrapper className="container">
          <PictureWrapper className="col-md-1">
            <WorkStyleTitle>Leader &amp; Innovator</WorkStyleTitle>
            <WorkStyleImage
              src={workStyleImage}
              alt="Leader / Innovator Work Style"
              className="rounded-circle"
            />
          </PictureWrapper>
          <DefinitionWrapper className="col-sm-12 col-md-6">
            <div className="workStyle-definition-container">
              <Leader></Leader>
              <Innovator></Innovator>
              <Partner></Partner>
              <Artisan></Artisan>
              <Producer></Producer>
            </div>
          </DefinitionWrapper>
        </ContentWrapper>
      </div>
    </div>
  );
};

export default WorkStyle;
