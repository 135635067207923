import React from "react";
import ReactDOM from "react-dom";
import GA4React from "ga-4-react";
import MetaTags from "react-meta-tags";

import App from "./App";
import GlobalStyles from "./themes/GlobalStyles";
import { ThemeProvider } from "styled-components";
import { theme } from "./themes/theme";

const ga4react = new GA4React("G-ZEHTD0PLXD");

(async () => {
  await ga4react.initialize();

  ReactDOM.render(
    <div>
      <MetaTags>
        <title>
          Full-Stack Software Architect &amp; 10 Year Digital Nomad - Web &amp;
          Software Portfolio
        </title>

        <meta
          name="keywords"
          content="Brandon Osborne, Web Development, Software Development, ASP.Net, .Net Core, Javascript, ColdFusion, C#, jQuery, SQL Server"
        />
        <meta
          name="description"
          content="I'm a globe trotting web developer &amp; entrepreneur with more than twenty years of full-stack experience in helping people to accomplish their goals and dreams!"
        />

        <meta
          property="og:title"
          content="Full-Stack Software Architect &amp; 10 Year Digital Nomad - Web &amp; Software Portfolio"
        />
        <meta
          property="og:image"
          content="https://coderpromedia.azureedge.net/images/BrandonOsborneOrangeSoftwareDeveloper.jpg"
        />
        <meta
          property="og:description"
          content="I'm a globe trotting web developer &amp; entrepreneur with more than twenty years of full-stack experience in helping people to accomplish their goals and dreams!"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://brandonosborne.com/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Brandon Osborne's Folio" />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:image"
          content="https://coderpromedia.azureedge.net/images/BrandonOsborneOrangeSoftwareDeveloper.jpg"
        />

        <meta name="twitter:site" content="https://brandonosborne.com" />
        <meta name="twitter:creator" content="@MadCoder312" />
        <meta
          name="twitter:title"
          content="Full-Stack Software Architect &amp; 10 Year Digital Nomad"
        />
        <meta
          name="twitter:description"
          content="I'm a globe trotting web developer &amp; entrepreneur with more than twenty years of full-stack experience in helping people to accomplish their goals and dreams!"
        />
        <meta name="twitter:url" content="https://brandonosborne.com/" />
      </MetaTags>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </div>,
    document.getElementById("root")
  );
})();
