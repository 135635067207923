import React from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReCAPTCHA from "react-google-recaptcha";
import GA4React from "ga-4-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserAlien,
  faMailbox,
  faPhone,
  faRocketLaunch,
} from "@fortawesome/pro-duotone-svg-icons";

import "../css/contact.css";

const ga4react = new GA4React("G-ZEHTD0PLXD");

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      copyMail: false,
      recaptchaRef: React.createRef(),
    };
  }

  resetForm() {
    this.setState({
      name: "",
      email: "",
      phone: "",
      message: "",
      copyMail: false,
      recaptchaRef: React.createRef(),
    });
  }

  handleSubmit(e) {
    // Send email.
    var recaptchaValue = this.state.recaptchaRef.current.getValue();
    var api_key = "key-143702956f3b5a0734596c2f6f7e2b55";
    var domain = "mg.brandonosborne.com";
    var mailgun = require("mailgun-js")({ apiKey: api_key, domain: domain });

    if (recaptchaValue === "") {
      toast.error("Please complete the recaptcha to prove you are human");
    } else {
      var data = {
        from: `${this.state.name} <${this.state.email}>`,
        to: "me@brandonosborne.com",
        subject: "New message from brandonosborne.com",
        text: `Name: ${this.state.name}\n
Email: ${this.state.email}\n
Phone: ${this.state.phone}\n
\n
Message:\n
${this.state.message}`,
      };

      var copyData = {
        from: `noreply@mg.coderpro.net`,
        to: this.state.email,
        subject: "Your message to Brandon Osborne",
        text: `Name: ${this.state.name}\n
Email: ${this.state.email}\n
Phone: ${this.state.phone}\n
\n
Message:\n
${this.state.message}`,
      };

      // If not bot then send me the message.
      mailgun.messages().send(data, function (error, response) {
        if (typeof error !== "undefined") {
          // send error notification.
          toast.error(
            "There was an error sending your message. Please try again later."
          );
        } else {
          // send success notification.
          toast.success("Your message has been sent. Thank you!");
          ga4react.event("contact_form", "", "contact", false);
        }
      });

      if (this.state.copyMail) {
        // Send the copy message if requested.
        mailgun.messages().send(copyData, function (error, response) {
          if (typeof error !== "undefined") {
            // send error notification.
            toast.error(
              "There was an error sending your message. Please try again later."
            );
          } else {
            // send success notification.
            toast.success("Your copy of the message has been sent. Thank you!");
          }
        });
      }

      this.resetForm();
    }

    e.preventDefault();
  }

  onNameChange(e) {
    this.setState({ name: e.target.value });
  }

  onEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  onPhoneChange(e) {
    this.setState({ phone: e.target.value });
  }

  onMessageChange(e) {
    this.setState({ message: e.target.value });
  }

  onCheckboxChange(e) {
    this.setState({ copyMail: e.target.checked });
  }

  render() {
    return (
      <div className="container-contact100">
        <div className="wrap-contact100">
          <form
            autoComplete="off"
            className="contact100-form validate-form"
            id="contact-form"
            onSubmit={this.handleSubmit.bind(this)}
            method="post">
            <div className="contact100-form-checkbox">
              <input
                className=""
                id="ckb1"
                type="checkbox"
                name="copy-mail"
                onChange={this.onCheckboxChange.bind(this)}
              />

              <label className="label-checkbox100" htmlFor="ckb1">
                Send copy to my e-mail
              </label>
            </div>
            <div
              className="wrap-input100 validate-input"
              data-validate="Name is required">
              <input
                className="input100"
                id="name"
                type="text"
                name="name"
                placeholder="Name"
                required
                value={this.state.name}
                onChange={this.onNameChange.bind(this)}
              />
              <label className="label-input100" htmlFor="name">
                <span className="lnr lnr-user">
                  <FontAwesomeIcon icon={faUserAlien} />
                </span>
              </label>
            </div>
            <div
              className="wrap-input100 validate-input"
              data-validate="Valid email is required: ex@abc.xyz">
              <input
                className="input100"
                id="email"
                type="email"
                name="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.onEmailChange.bind(this)}
                required
              />
              <label className="label-input100" htmlFor="email">
                <span className="lnr lnr-envelope">
                  <FontAwesomeIcon icon={faMailbox} />
                </span>
              </label>
            </div>

            <div
              className="wrap-input100 validate-input"
              data-validate="Phone is required">
              <input
                className="input100"
                id="phone"
                type="tel"
                name="phone"
                placeholder="Phone"
                value={this.state.phone}
                onChange={this.onPhoneChange.bind(this)}
              />
              <label className="label-input100" htmlFor="phone">
                <span className="lnr lnr-phone-handset">
                  <FontAwesomeIcon icon={faPhone} />
                </span>
              </label>
            </div>

            <div
              className="wrap-input100 validate-input"
              data-validate="Message is required">
              <textarea
                className="input100"
                name="message"
                placeholder="Your message..."
                value={this.state.message}
                required
                onChange={this.onMessageChange.bind(this)}></textarea>
            </div>
            <div>
              <ReCAPTCHA
                ref={this.state.recaptchaRef}
                sitekey="6Le6fIcaAAAAAOAVLFUb-dGrzsKIvE9TfW0yO5rc"
                theme="dark"
              />
              <br />
            </div>
            <div className="container-contact100-form-btn">
              <div className="wrap-contact100-form-btn">
                <div className="contact100-form-bgbtn"></div>
                <button className="button ice">
                  <FontAwesomeIcon icon={faRocketLaunch} spin />
                  &nbsp; Send Email
                </button>
              </div>
            </div>
          </form>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default ContactForm;
