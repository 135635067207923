import React from "react";
import { Modal } from "react-bootstrap";

import iconImage from "../../images/workstyle/innovator-icon.svg";

class Innovator extends React.Component {
  state = { show: false };

  update = (show) => {
    this.setState({ show });
  };

  handleShow = (show) => {
    this.setState({ show: true });
  };

  handleClose = (show) => {
    this.setState({ show: false });
  };

  render() {
    return (
      <>
        <a
          href="##"
          className="flex mb-4 items-center"
          onClick={this.handleShow}>
          <img src={iconImage} alt="Innovator" />
          <div>
            <h4 className="workStyle-title">innovator</h4>
            <p className="text-white">
              Innovators love tough problems.
            </p>
          </div>
        </a>

        <WorkStyleDetailModal {...this} title="Innovator" />
      </>
    );

    function WorkStyleDetailModal(props) {
      return (
        <Modal
          show={props.state.show}
          onHide={props.handleClose}
          dialogClassName="modal-xl">
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>
              <h4>{props.title}</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modalBody-container" className="container innovator">
              <div className="row">
                <div className="col-lg-9 col-sm-12">
                  <h3 id="high-level-description">High-level description:</h3>
                  <p>
                    Innovators love tough problems and thrive when projects need
                    a healthy dose of outside-the-box thinking or require
                    creative solutions. Innovators need ample space for
                    innovation (of course), but also celebrate and prioritize
                    original thinking.
                  </p>
                  <h3 id="in-your-ideal-work-environment">
                    In your ideal work environment:
                  </h3>
                  <p>
                    You are alert, insightful, and curious. You’re able to
                    concentrate and focus on developing complex ideas and
                    solutions. You’re independent, innovative, and inventive.
                    Your teams look to you as a visionary; you’re often ahead of
                    your time and able to see the world in an entirely new way.
                    You are intellectually curious and able to sustain long
                    periods of deep concentration in things that interest you.
                  </p>
                  <h3 id="in-a-strained-work-environment">
                    In a strained work environment:
                  </h3>
                  <p>
                    You tend to overthink and overanalyze things. You may begin
                    conceptualizing and fine-tuning everything before acting or
                    working things out in your mind (modeling, preparing,
                    testing, and gathering more information) to guard against
                    failure. You believe your value is built around “having
                    ideas,” so exploring what is already familiar and
                    well-established seems boring even if it’s the highest
                    priority work. You see many possible solutions, but often do
                    not know how to choose among them or judge which is more or
                    less important.
                  </p>
                </div>
                <div className="col-lg-3 col-sm-12 advice">
                  <h4 className="pt-3 pb-1">Advice</h4>
                  <p>
                    It can often be helpful to get the advice of someone whose
                    judgment you trust while you are gaining perspective on your
                    solution. Doing this will also help you trust someone else,
                    which can sometimes be difficult for you. Be decisive!
                    Decisive action (even if it results in failure sometimes)
                    will bring more confidence than gathering additional facts
                    or researching on Stack Overflow.
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
  }
}

export default Innovator;
