import React from "react";
import { Modal } from "react-bootstrap";

import iconImage from "../../images/workstyle/producer-icon.svg";

class Producer extends React.Component {
  state = { show: false };

  update = (show) => {
    this.setState({ show });
  };

  handleShow = (show) => {
    this.setState({ show: true });
  };

  handleClose = (show) => {
    this.setState({ show: false });
  };

  render() {
    return (
      <>
        <a
          href="##"
          className="flex mb-4 items-center"
          onClick={this.handleShow}>
          <img src={iconImage} alt="Producer" />
          <div>
            <h4 className="workStyle-title">producer</h4>
            <p className="text-white">
              Producers are the horsepower in any engineering team's engine.
            </p>
          </div>
        </a>

        <WorkStyleDetailModal {...this} title="Producer" />
      </>
    );

    function WorkStyleDetailModal(props) {
      return (
        <Modal
          show={props.state.show}
          onHide={props.handleClose}
          dialogClassName="modal-xl">
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>
              <h4>{props.title}</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modalBody-container" className="container producer">
              <div className="row">
                <div className="col-lg-9 col-sm-12">
                  <h3 id="high-level-description">High-level description:</h3>
                  <p>
                    Producers are the horsepower in any engineering team’s
                    engine. They are reliable, self-reliant, often independent
                    high-performers. Producers thrive on a high degree of
                    autonomy, feedback (both positive and constructive), and
                    clarity around product goals.
                  </p>
                  <h3 id="in-your-ideal-work-environment">
                    In your ideal work environment:
                  </h3>
                  <p>
                    You are self-assured, energetic, and competent, with a high
                    degree of self-esteem. You believe in yourself and you know
                    your own value. You’re adaptable, influential, and
                    energizing to work with. You are committed to
                    self-improvement and want to be “the best you can be.” You
                    are highly effective, motivating others to be like you in a
                    positive way.
                  </p>
                  <h3 id="in-a-strained-work-environment">
                    In a strained work environment:
                  </h3>
                  <p>
                    You tend to get overly concerned with your performance and
                    may push yourself unnecessarily hard to achieve impossible
                    goals. You are always pragmatic and efficient but can also
                    become image-conscious, and overly concerned with the
                    expectations and perception of others. When stressed, you
                    avoid collaboration and prefer to go ‘heads-down’, sometimes
                    to your own (or the project’s) detriment.
                  </p>
                </div>
                <div className="col-lg-3 col-sm-12 advice">
                  <h4 className="pt-3 pb-1">Advice</h4>
                  <p>
                    Learn when to take breaks. You can drive yourself and others
                    to exhaustion with your relentless pursuit of goals.
                    Ambition and self-development are good qualities, but temper
                    them with rest periods to recharge. Sometimes taking three
                    to five deep breaths is enough to improve your outlook. Work
                    in tandem with others more. Working cooperatively toward
                    goals that transcend personal interest is a powerful way of
                    finding your true value and identity.
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
  }
}

export default Producer;
