import React from "react";
import styled, { keyframes } from "styled-components";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import useToggle from "../hooks/useToggle";
import Carousel from "nuka-carousel";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaCode, FaExternalLinkAlt, FaVimeo } from "react-icons/fa";
import Fade from "react-reveal/Fade";
import Tooltip from "./Tooltip";

import med1 from "../images/med-1.jpg";
import med2 from "../images/med-2.jpg";
import med3 from "../images/med-3.jpg";
import med4 from "../images/med-4.jpg";

import react1 from "../images/react-1.png";
import react2 from "../images/react-2.png";

import buyCoders1 from "../images/buyCoders-1.png";

import pc1 from "../images/pc-1.png";
import pc2 from "../images/pc-2.png";
import pc3 from "../images/pc-3.png";
import pc4 from "../images/pc-4.png";

import sms1 from "../images/sms-1.png";
import sms2 from "../images/sms-2.png";
import sms3 from "../images/sms-3.png";

import d31 from "../images/diablo3-1.png";
import d32 from "../images/diablo3-2.png";
import d33 from "../images/diablo3-3.png";

import ma1 from "../images/mavue-1.png";
import ma2 from "../images/mavue-2.png";
import ma3 from "../images/mavue-3.png";

import fe1 from "../images/fest-1.png";
import fe2 from "../images/fest-2.png";
import fe3 from "../images/fest-3.png";
import fe4 from "../images/fest-4.png";
import fe5 from "../images/fest-5.png";

import nbp1 from "../images/nbp-1.png";
import nbp2 from "../images/nbp-2.png";
import nbp3 from "../images/nbp-3.png";
import nbp4 from "../images/nbp-4.png";

import bw1 from "../images/bw-1.png";
import bw2 from "../images/bw-2.png";
import bw3 from "../images/bw-3.png";
import bw4 from "../images/bw-4.png";

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.background};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5%;
`;

const Header = styled.h1`
  color: ${(props) => props.theme.high};
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 700;
  margin: 1.5rem 0 3rem 0;
  font-family: "Raleway", sans-serif;
  letter-spacing: 2px;
  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 45%;
    padding-top: 0.35em;
    border-bottom: 3px solid white;
  }
`;

const SelectionCard = styled.div`
  height: 17em;
  width: 28em;
  min-width: 28em;
  background-color: ${(props) => props.theme.elevated};
  color: ${(props) => props.theme.high};
  font-family: "raleway";
  margin: 1.5rem;
  display: block;
  border-radius: 5px;
  @media (max-width: 475px) {
    width: 22em;
    min-width: 22em;
    height: 14em;
    margin: 1em;
  }
  @media (max-width: 375px) {
    width: 17em;
    min-width: 17em;
    height: 11em;
    margin: 1em;
    margin: 0.5em;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  background-color: #2a4051;
  height: 84.706%;
  margin: 0.2em;
  border-radius: 5px;
`;

const ProjectImage = styled.div`
  height: 100%;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  background-size: cover;
`;

const InfoWrapper = styled.div`
  margin-top: -0.2em;
  height: 2.4em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5em;
  @media (max-width: 475px) {
    margin-top: -0.4em;
  }
  @media (max-width: 375px) {
    margin-top: -0.65rem;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
`;

const ProjectTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 500;
  @media (max-width: 475px) {
    font-size: 0.9rem;
  }
  @media (max-width: 375px) {
    font-size: 0.7rem;
  }
`;

const ProjectLink = styled.a`
  font-size: 0.75rem;
  font-weight: 400;
  font-family: sans-serif;
  cursor: pointer;
  &:first-of-type {
    padding-right: 0.75em;
  }
  &:nth-of-type(2) {
    padding-left: 0.75em;
  }
  &:hover {
    color: ${(props) => props.theme.primary};
  }
`;

const SelectionWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const SelectionRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 1005px) {
    flex-direction: column;
  }
`;

const fade = keyframes`
  from {
    transform: scale(0.8);
    opacity:0;
  }
  to {
    transform: scale(1);
    opacity:1;
  }
`;

const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  border-radius: 5px;
  transition: 0.5s ease;
  background-color: ${(props) => props.theme.elevated2};
  &:hover {
    opacity: 1;
  }
`;

const OverlayText = styled.p`
  font-family: sans-serif;
  font-size: 1rem;
  @media (max-width: 475px) {
    font-size: 0.9rem;
  }
  @media (max-width: 375px) {
    font-size: 0.7rem;
  }
`;

const OverlayContainer = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 3.5em;
  text-align: center;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  &:hover {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  @media (max-width: 475px) {
    padding: 0 2em;
  }
  @media (max-width: 375px) {
    padding: 0 1rem;
  }
`;

const OverlayButton = styled.button`
  outline: 0;
  border: 2px solid;
  border-color: ${(props) => props.theme.primary};
  background: none;
  margin: 25px;
  padding: 10px 35px;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
  border-radius: 50px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:hover {
    background: ${(props) => props.theme.primary};
  }
  @media (max-width: 475px) {
    font-size: 0.9rem;
  }
  @media (max-width: 375px) {
    font-size: 0.7rem;
    padding: 5px 20px;
  }
`;

const ModalImage = styled.div`
  background-size: 100% 100%;
  cursor: default;
  width: 80em;
  height: 40em;
  @media (max-width: 1400px) {
    width: 70em;
    height: 35em;
  }
  @media (max-width: 1200px) {
    width: 64em;
    height: 32em;
  }
  @media (max-width: 1140px) {
    width: 61em;
    height: 28em;
  }
  @media (max-width: 1075px) {
    width: 56em;
    height: 25em;
  }
  @media (max-width: 850px) {
    width: 43em;
    height: 20em;
  }
  @media (max-width: 770px) {
    width: 39em;
    height: 18em;
  }
  @media (max-width: 690px) {
    width: 35em;
    height: 16em;
  }
  @media (max-width: 610px) {
    width: 31.5em;
    height: 14.5em;
  }
  @media (max-width: 530px) {
    width: 27em;
    height: 13em;
  }
  @media (max-width: 450px) {
    width: 25em;
    height: 12em;
  }
  @media (max-width: 400px) {
    width: 23em;
    height: 11em;
  }
  @media (max-width: 370px) {
    width: 21em;
    height: 10em;
  }
  @media (max-width: 340px) {
    width: 19em;
    height: 9em;
  }
  @media (max-width: 310px) {
    width: 17em;
    height: 8em;
  }
`;

const StyledContent = styled(DialogContent)`
  width: 80em;
  height: 40em;
  padding: 0;
  border-radius: 5px;
  animation: ${fade} 0.2s ease-in;
  @media (max-width: 1400px) {
    width: 70em;
    height: 35em;
  }
  @media (max-width: 1200px) {
    width: 64em;
    height: 32em;
  }
  @media (max-width: 1140px) {
    width: 61em;
    height: 28em;
  }
  @media (max-width: 1075px) {
    width: 56em;
    height: 25em;
  }
  @media (max-width: 850px) {
    width: 43em;
    height: 20em;
  }
  @media (max-width: 770px) {
    width: 39em;
    height: 18em;
  }
  @media (max-width: 690px) {
    width: 35em;
    height: 16em;
  }
  @media (max-width: 610px) {
    width: 31.5em;
    height: 14.5em;
  }
  @media (max-width: 530px) {
    width: 27em;
    height: 13em;
  }
  @media (max-width: 450px) {
    width: 25em;
    height: 12em;
  }
  @media (max-width: 400px) {
    width: 23em;
    height: 11em;
  }
  @media (max-width: 370px) {
    width: 21em;
    height: 10em;
  }
  @media (max-width: 340px) {
    width: 19em;
    height: 9em;
  }
  @media (max-width: 310px) {
    width: 17em;
    height: 8em;
  }
`;

const StyledDialogOverlay = styled(DialogOverlay)`
  background: rgba(55, 55, 55, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;
const ControlsContainer = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: center;
`;

const ControlsText = styled.p`
  color: ${(props) => props.theme.high};
  margin: 0 1em;
  font-size: 1.1rem;
  font-weight: 700;
  @media (max-width: 850px) {
    font-size: 1rem;
  }
  @media (max-width: 690px) {
    display: none;
  }
`;

const ColorText = styled.span`
  color: ${(props) => props.theme.primaryV};
  font-weight: 500;
`;

const ControlButton = styled.button`
  background: none;
  border: none;
`;
const TextPrimary = styled.h3`
  font-size: 1.3rem;
  color: ${(props) => props.theme.high};
  letter-spacing: 1px;
  font-weight: 300;
  margin-bottom: 2rem;
  display: block;
  &:first-of-type {
    margin-bottom: 0.2rem;
  }
  @media (max-width: 540px) {
    font-size: 1rem;
  }
  @media (max-width: 440px) {
    font-size: 0.8rem;
  }
  @media (max-width: 365px) {
    font-size: 0.7rem;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  @media (max-width: 1005px) {
    display: block;
  }
`;

const StyledExternal = styled(FaExternalLinkAlt)`
  font-size: 1.2rem;
  color: ${(props) => props.theme.high};
  margin-top: 0.1em;
  @media (max-width: 475px) {
    font-size: 0.8rem;
    margin-top: 0.15em;
  }
`;

const StyledCode = styled(FaCode)`
  color: ${(props) => props.theme.high};
  font-size: 1.55rem;
  @media (max-width: 475px) {
    font-size: 1rem;
    margin-top: 0.05em;
  }
`;

const StyledVideo = styled(FaVimeo)`
  color: ${(props) => props.theme.high};
  font-size: 1.55rem;
  @media (max-width: 475px) {
    font-size: 1rem;
    margin-top: 0.05em;
  }
`;

const arrowStyles = `
font-size:60px;
color:#03DAC5;
&:hover{
    cursor:pointer;
}
@media(max-width: 690px){
    font-size:50px;
}
@media(max-width: 530px){
    font-size:40px;
}
@media(max-width: 375px){
    font-size:30px;
}
`;

const StyledBackArrow = styled(IoIosArrowBack)`
  ${arrowStyles}
`;

const StyledForwardArrow = styled(IoIosArrowForward)`
  ${arrowStyles}
`;

const Projects = () => {
  const [modalOne, toggleModalOne] = useToggle(false);
  const [modalTwo, toggleModalTwo] = useToggle(false);
  const [modalThree, toggleModalThree] = useToggle(false);
  const [modalFour, toggleModalFour] = useToggle(false);
  const [modalFive, toggleModalFive] = useToggle(false);
  const [modalSix, toggleModalSix] = useToggle(false);
  const [modalSeven, toggleModalSeven] = useToggle(false);
  const [modalEight, toggleModalEight] = useToggle(false);
  const [modalNine, toggleModalNine] = useToggle(false);
  const [modalTen, toggleModalTen] = useToggle(false);

  return (
    <Wrapper>
      <StyledDialogOverlay isOpen={modalOne} onDismiss={toggleModalOne}>
        <StyledContent>
          <Carousel
            enableKeyboardControls={true}
            renderCenterLeftControls={({ previousSlide }) => (
              <ControlButton onClick={previousSlide}>
                <StyledBackArrow />
              </ControlButton>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <ControlButton onClick={nextSlide}>
                <StyledForwardArrow />
              </ControlButton>
            )}>
            <ModalImage
              title="Case manager"
              style={{ backgroundImage: `url(${med1})` }}
            />
            <ModalImage
              title="Image uploader"
              style={{ backgroundImage: `url(${med2})` }}
            />
            <ModalImage
              title="Reporting with Kendo-UI"
              style={{ backgroundImage: `url(${med3})` }}
            />
            <ModalImage
              title="The user manager"
              style={{ backgroundImage: `url(${med4})` }}
            />
          </Carousel>
          <ControlsContainer>
            <ControlsText>
              A: <ColorText>Previous</ColorText>
            </ControlsText>
            <ControlsText>
              D: <ColorText>Next</ColorText>
            </ControlsText>
            <ControlsText>
              Q: <ColorText>First</ColorText>
            </ControlsText>
            <ControlsText>
              E: <ColorText>Last</ColorText>
            </ControlsText>
            <ControlsText>
              ESC: <ColorText>Exit</ColorText>
            </ControlsText>
          </ControlsContainer>
        </StyledContent>
      </StyledDialogOverlay>
      <StyledDialogOverlay isOpen={modalTwo} onDismiss={toggleModalTwo}>
        <StyledContent>
          <Carousel
            enableKeyboardControls={true}
            renderCenterLeftControls={({ previousSlide }) => (
              <ControlButton onClick={previousSlide}>
                <StyledBackArrow />
              </ControlButton>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <ControlButton onClick={nextSlide}>
                <StyledForwardArrow />
              </ControlButton>
            )}>
            <ModalImage
              title="Standard Azure active directory B2C login"
              style={{ backgroundImage: `url(${react1})` }}
            />
            <ModalImage
              title="Book Manager view"
              style={{ backgroundImage: `url(${react2})` }}
            />
          </Carousel>
          <ControlsContainer>
            <ControlsText>
              A: <ColorText>Previous</ColorText>
            </ControlsText>
            <ControlsText>
              D: <ColorText>Next</ColorText>
            </ControlsText>
            <ControlsText>
              Q: <ColorText>First</ColorText>
            </ControlsText>
            <ControlsText>
              E: <ColorText>Last</ColorText>
            </ControlsText>
            <ControlsText>
              ESC: <ColorText>Exit</ColorText>
            </ControlsText>
          </ControlsContainer>
        </StyledContent>
      </StyledDialogOverlay>
      <StyledDialogOverlay isOpen={modalThree} onDismiss={toggleModalThree}>
        <StyledContent>
          <Carousel
            enableKeyboardControls={true}
            renderCenterLeftControls={({ previousSlide }) => (
              <ControlButton onClick={previousSlide}>
                <StyledBackArrow />
              </ControlButton>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <ControlButton onClick={nextSlide}>
                <StyledForwardArrow />
              </ControlButton>
            )}>
            <ModalImage
              title="Top Secret"
              style={{ backgroundImage: `url(${buyCoders1})` }}
            />
          </Carousel>
          <ControlsContainer>
            <ControlsText>
              A: <ColorText>Previous</ColorText>
            </ControlsText>
            <ControlsText>
              D: <ColorText>Next</ColorText>
            </ControlsText>
            <ControlsText>
              Q: <ColorText>First</ColorText>
            </ControlsText>
            <ControlsText>
              E: <ColorText>Last</ColorText>
            </ControlsText>
            <ControlsText>
              ESC: <ColorText>Exit</ColorText>
            </ControlsText>
          </ControlsContainer>
        </StyledContent>
      </StyledDialogOverlay>
      <StyledDialogOverlay isOpen={modalFour} onDismiss={toggleModalFour}>
        <StyledContent>
          <Carousel
            enableKeyboardControls={true}
            renderCenterLeftControls={({ previousSlide }) => (
              <ControlButton onClick={previousSlide}>
                <StyledBackArrow />
              </ControlButton>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <ControlButton onClick={nextSlide}>
                <StyledForwardArrow />
              </ControlButton>
            )}>
            <ModalImage
              title="The landing page"
              style={{ backgroundImage: `url(${pc1})` }}
            />
            <ModalImage
              title="Product listing page"
              style={{ backgroundImage: `url(${pc2})` }}
            />
            <ModalImage
              title="Ticket purchasing system"
              style={{ backgroundImage: `url(${pc3})` }}
            />
            <ModalImage
              title="Ticket management"
              style={{ backgroundImage: `url(${pc4})` }}
            />
          </Carousel>
          <ControlsContainer>
            <ControlsText>
              A: <ColorText>Previous</ColorText>
            </ControlsText>
            <ControlsText>
              D: <ColorText>Next</ColorText>
            </ControlsText>
            <ControlsText>
              Q: <ColorText>First</ColorText>
            </ControlsText>
            <ControlsText>
              E: <ColorText>Last</ColorText>
            </ControlsText>
            <ControlsText>
              ESC: <ColorText>Exit</ColorText>
            </ControlsText>
          </ControlsContainer>
        </StyledContent>
      </StyledDialogOverlay>
      <StyledDialogOverlay isOpen={modalFive} onDismiss={toggleModalFive}>
        <StyledContent>
          <Carousel
            enableKeyboardControls={true}
            renderCenterLeftControls={({ previousSlide }) => (
              <ControlButton onClick={previousSlide}>
                <StyledBackArrow />
              </ControlButton>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <ControlButton onClick={nextSlide}>
                <StyledForwardArrow />
              </ControlButton>
            )}>
            <ModalImage
              title="The landing page"
              style={{ backgroundImage: `url(${sms1})` }}
            />
            <ModalImage
              title="Product listing page"
              style={{ backgroundImage: `url(${sms2})` }}
            />
            <ModalImage
              title="Ticket purchasing system"
              style={{ backgroundImage: `url(${sms3})` }}
            />
          </Carousel>
          <ControlsContainer>
            <ControlsText>
              A: <ColorText>Previous</ColorText>
            </ControlsText>
            <ControlsText>
              D: <ColorText>Next</ColorText>
            </ControlsText>
            <ControlsText>
              Q: <ColorText>First</ColorText>
            </ControlsText>
            <ControlsText>
              E: <ColorText>Last</ColorText>
            </ControlsText>
            <ControlsText>
              ESC: <ColorText>Exit</ColorText>
            </ControlsText>
          </ControlsContainer>
        </StyledContent>
      </StyledDialogOverlay>
      <StyledDialogOverlay isOpen={modalSix} onDismiss={toggleModalSix}>
        <StyledContent>
          <Carousel
            enableKeyboardControls={true}
            renderCenterLeftControls={({ previousSlide }) => (
              <ControlButton onClick={previousSlide}>
                <StyledBackArrow />
              </ControlButton>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <ControlButton onClick={nextSlide}>
                <StyledForwardArrow />
              </ControlButton>
            )}>
            <ModalImage
              title="&nbsp;"
              style={{ backgroundImage: `url(${d31})` }}
            />
            <ModalImage
              title="Farming for Riches"
              style={{ backgroundImage: `url(${d32})` }}
            />
            <ModalImage
              title="Death by Automation"
              style={{ backgroundImage: `url(${d33})` }}
            />
          </Carousel>
          <ControlsContainer>
            <ControlsText>
              A: <ColorText>Previous</ColorText>
            </ControlsText>
            <ControlsText>
              D: <ColorText>Next</ColorText>
            </ControlsText>
            <ControlsText>
              Q: <ColorText>First</ColorText>
            </ControlsText>
            <ControlsText>
              E: <ColorText>Last</ColorText>
            </ControlsText>
            <ControlsText>
              ESC: <ColorText>Exit</ColorText>
            </ControlsText>
          </ControlsContainer>
        </StyledContent>
      </StyledDialogOverlay>

      <StyledDialogOverlay isOpen={modalSeven} onDismiss={toggleModalSeven}>
        <StyledContent>
          <Carousel
            enableKeyboardControls={true}
            renderCenterLeftControls={({ previousSlide }) => (
              <ControlButton onClick={previousSlide}>
                <StyledBackArrow />
              </ControlButton>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <ControlButton onClick={nextSlide}>
                <StyledForwardArrow />
              </ControlButton>
            )}>
            <ModalImage
              title="&nbsp;"
              style={{ backgroundImage: `url(${ma1})` }}
            />
            <ModalImage
              title="&nbsp;"
              style={{ backgroundImage: `url(${ma2})` }}
            />
            <ModalImage
              title="&nbsp;"
              style={{ backgroundImage: `url(${ma3})` }}
            />
          </Carousel>
          <ControlsContainer>
            <ControlsText>
              A: <ColorText>Previous</ColorText>
            </ControlsText>
            <ControlsText>
              D: <ColorText>Next</ColorText>
            </ControlsText>
            <ControlsText>
              Q: <ColorText>First</ColorText>
            </ControlsText>
            <ControlsText>
              E: <ColorText>Last</ColorText>
            </ControlsText>
            <ControlsText>
              ESC: <ColorText>Exit</ColorText>
            </ControlsText>
          </ControlsContainer>
        </StyledContent>
      </StyledDialogOverlay>

      <StyledDialogOverlay isOpen={modalEight} onDismiss={toggleModalEight}>
        <StyledContent>
          <Carousel
            enableKeyboardControls={true}
            renderCenterLeftControls={({ previousSlide }) => (
              <ControlButton onClick={previousSlide}>
                <StyledBackArrow />
              </ControlButton>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <ControlButton onClick={nextSlide}>
                <StyledForwardArrow />
              </ControlButton>
            )}>
            <ModalImage
              title="&nbsp;"
              style={{ backgroundImage: `url(${fe1})` }}
            />
            <ModalImage
              title="&nbsp;"
              style={{ backgroundImage: `url(${fe2})` }}
            />
            <ModalImage
              title="&nbsp;"
              style={{ backgroundImage: `url(${fe3})` }}
            />
            <ModalImage
              title="&nbsp;"
              style={{ backgroundImage: `url(${fe4})` }}
            />
            <ModalImage title="" style={{ backgroundImage: `url(${fe5})` }} />
          </Carousel>
          <ControlsContainer>
            <ControlsText>
              A: <ColorText>Previous</ColorText>
            </ControlsText>
            <ControlsText>
              D: <ColorText>Next</ColorText>
            </ControlsText>
            <ControlsText>
              Q: <ColorText>First</ColorText>
            </ControlsText>
            <ControlsText>
              E: <ColorText>Last</ColorText>
            </ControlsText>
            <ControlsText>
              ESC: <ColorText>Exit</ColorText>
            </ControlsText>
          </ControlsContainer>
        </StyledContent>
      </StyledDialogOverlay>

      <StyledDialogOverlay isOpen={modalNine} onDismiss={toggleModalNine}>
        <StyledContent>
          <Carousel
            enableKeyboardControls={true}
            renderCenterLeftControls={({ previousSlide }) => (
              <ControlButton onClick={previousSlide}>
                <StyledBackArrow />
              </ControlButton>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <ControlButton onClick={nextSlide}>
                <StyledForwardArrow />
              </ControlButton>
            )}>
            <ModalImage
              title="Auction catalogue"
              style={{ backgroundImage: `url(${bw1})` }}
            />
            <ModalImage
              title="Login page with toast"
              style={{ backgroundImage: `url(${bw2})` }}
            />
            <ModalImage
              title="Properties up for auction"
              style={{ backgroundImage: `url(${bw3})` }}
            />
            <ModalImage
              title="Property detail page"
              style={{ backgroundImage: `url(${bw4})` }}
            />
          </Carousel>
          <ControlsContainer>
            <ControlsText>
              A: <ColorText>Previous</ColorText>
            </ControlsText>
            <ControlsText>
              D: <ColorText>Next</ColorText>
            </ControlsText>
            <ControlsText>
              Q: <ColorText>First</ColorText>
            </ControlsText>
            <ControlsText>
              E: <ColorText>Last</ColorText>
            </ControlsText>
            <ControlsText>
              ESC: <ColorText>Exit</ColorText>
            </ControlsText>
          </ControlsContainer>
        </StyledContent>
      </StyledDialogOverlay>
      <StyledDialogOverlay isOpen={modalTen} onDismiss={toggleModalTen}>
        <StyledContent>
          <Carousel
            enableKeyboardControls={true}
            renderCenterLeftControls={({ previousSlide }) => (
              <ControlButton onClick={previousSlide}>
                <StyledBackArrow />
              </ControlButton>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <ControlButton onClick={nextSlide}>
                <StyledForwardArrow />
              </ControlButton>
            )}>
            <ModalImage
              title="Dashboard with hidable columns"
              style={{ backgroundImage: `url(${nbp1})` }}
            />
            <ModalImage
              title="Flyout right panel for data display and updates"
              style={{ backgroundImage: `url(${nbp2})` }}
            />
            <ModalImage
              title="Flyout right panel for data display and updates"
              style={{ backgroundImage: `url(${nbp3})` }}
            />
            <ModalImage
              title="Payment history with heavily customized HighCharts.js"
              style={{ backgroundImage: `url(${nbp4})` }}
            />
          </Carousel>
          <ControlsContainer>
            <ControlsText>
              A: <ColorText>Previous</ColorText>
            </ControlsText>
            <ControlsText>
              D: <ColorText>Next</ColorText>
            </ControlsText>
            <ControlsText>
              Q: <ColorText>First</ColorText>
            </ControlsText>
            <ControlsText>
              E: <ColorText>Last</ColorText>
            </ControlsText>
            <ControlsText>
              ESC: <ColorText>Exit</ColorText>
            </ControlsText>
          </ControlsContainer>
        </StyledContent>
      </StyledDialogOverlay>
      <Header>Recent Projects</Header>
      <Fade left>
        <TextWrapper>
          <TextPrimary>
            Here are some of my recent <ColorText>projects</ColorText>.&nbsp;
          </TextPrimary>
          <TextPrimary>
            Hover a project card to view more <ColorText>details</ColorText>.
          </TextPrimary>
        </TextWrapper>
      </Fade>
      <SelectionWrapper>
        <SelectionRow>
          <SelectionCard>
            <ImageContainer>
              <ProjectImage style={{ backgroundImage: `url(${nbp1})` }} />
              <ModalOverlay>
                <OverlayContainer>
                  <OverlayText>
                    This is a legacy <ColorText>ASP.Net MVC 1.0</ColorText> web
                    application that I'm responsible for modernizing. I started
                    by replacing the semi-responsive UI with CoreUI &amp; am in
                    the process of upgrading the site to utilize the latest and
                    greatest - <ColorText>ASP.Net 6 MVC.</ColorText>
                  </OverlayText>
                  <OverlayButton onClick={toggleModalTen}>
                    See More
                  </OverlayButton>
                </OverlayContainer>
              </ModalOverlay>
            </ImageContainer>
            <InfoWrapper>
              <ProjectTitle>NexBillPay</ProjectTitle>
              <ProjectLink href="https://nexbillpay.com" target="_blank">
                <Tooltip text="Visit Website">
                  <StyledExternal />
                </Tooltip>
              </ProjectLink>
            </InfoWrapper>
          </SelectionCard>
          <SelectionCard>
            <ImageContainer>
              <ProjectImage
                onClick={toggleModalNine}
                style={{ backgroundImage: `url(${bw1})` }}
              />
              <ModalOverlay>
                <OverlayContainer>
                  <OverlayText>
                    This is a suite of a dozen sites that I've been maintaining
                    and slowly modernizing. The main site was developed in{" "}
                    legacy <ColorText>ColdFusion</ColorText> with a{" "}
                    <ColorText>MySQL</ColorText> backend. The supporting sites
                    were developed in <ColorText>ReactJS</ColorText>. I'm also
                    responsible for{" "}
                    <ColorText>server administration.</ColorText>
                  </OverlayText>
                  <OverlayButton onClick={toggleModalNine}>
                    See More
                  </OverlayButton>
                </OverlayContainer>
              </ModalOverlay>
            </ImageContainer>
            <InfoWrapper>
              <ProjectTitle>Biddle &amp; Webb</ProjectTitle>
              <LinkWrapper>
                <ProjectLink href="https://biddleandwebb.co.uk" target="_blank">
                  <Tooltip text="Visit Biddle &amp; Webb Auctions">
                    <StyledExternal />
                  </Tooltip>
                </ProjectLink>
                <ProjectLink
                  href="https://property.biddleandwebb.com"
                  target="_blank">
                  <Tooltip text="Visit B&amp;W Property Sales">
                    <StyledExternal />
                  </Tooltip>
                </ProjectLink>
              </LinkWrapper>
            </InfoWrapper>
          </SelectionCard>
        </SelectionRow>

        <SelectionRow>
          <SelectionCard>
            <ImageContainer>
              <ProjectImage style={{ backgroundImage: `url(${fe1})` }} />
              <ModalOverlay>
                <OverlayContainer>
                  <OverlayText>
                    Upgraded the website to the latest version of{" "}
                    <ColorText>Umbraco</ColorText>. Diagnosed a bad host as the
                    source of their issues & then worked around the issues
                    caused by that host when the client refused to migrate.
                  </OverlayText>
                  <OverlayButton onClick={toggleModalEight}>
                    See More
                  </OverlayButton>
                </OverlayContainer>
              </ModalOverlay>
            </ImageContainer>
            <InfoWrapper>
              <ProjectTitle>FEST Film Festival Portugal</ProjectTitle>
              <ProjectLink href="https://fest.pt" target="_blank">
                <Tooltip text="Visit Website">
                  <StyledExternal />
                </Tooltip>
              </ProjectLink>
            </InfoWrapper>
          </SelectionCard>
          <SelectionCard>
            <ImageContainer>
              <ProjectImage
                onClick={toggleModalSeven}
                style={{ backgroundImage: `url(${ma1})` }}
              />
              <ModalOverlay>
                <OverlayContainer>
                  <OverlayText>
                    After months of mismanagement by another development firm, I
                    took this multi-lingual <ColorText>Umbraco</ColorText>{" "}
                    website, fixed bugs introduced by the other developer,
                    improved security, and added local, Facebook, and Google
                    <ColorText> authentication</ColorText>.
                  </OverlayText>
                  <OverlayButton onClick={toggleModalSeven}>
                    See More
                  </OverlayButton>
                </OverlayContainer>
              </ModalOverlay>
            </ImageContainer>
            <InfoWrapper>
              <ProjectTitle>MaVue Canada</ProjectTitle>
              <LinkWrapper>
                <ProjectLink href="https://vimeo.com/569806017" target="_blank">
                  <Tooltip text="View Demo">
                    <StyledVideo />
                  </Tooltip>
                </ProjectLink>
                <ProjectLink href="https://mavue.ca" target="_blank">
                  <Tooltip text="Visit Website">
                    <StyledExternal />
                  </Tooltip>
                </ProjectLink>
              </LinkWrapper>
            </InfoWrapper>
          </SelectionCard>
        </SelectionRow>

        <SelectionRow>
          <SelectionCard>
            <ImageContainer>
              <ProjectImage style={{ backgroundImage: `url(${med2})` }} />
              <ModalOverlay>
                <OverlayContainer>
                  <OverlayText>
                    Mobile application developed in .NET 5 Xamarin and WebAPIs.
                    The point of this application is to use
                    <ColorText> artificial intelligence </ColorText>
                    to scan images of lesions uploaded by users and determine
                    their probability of being cancerous. This also includes a
                    web app with Kendo UI to manage users and for doctors to
                    analyze photos.
                  </OverlayText>
                  <OverlayButton onClick={toggleModalOne}>
                    See More
                  </OverlayButton>
                </OverlayContainer>
              </ModalOverlay>
            </ImageContainer>
            <InfoWrapper>
              <ProjectTitle>MedAI</ProjectTitle>
            </InfoWrapper>
          </SelectionCard>
          <SelectionCard>
            <ImageContainer>
              <ProjectImage
                onClick={toggleModalTwo}
                style={{ backgroundImage: `url(${react1})` }}
              />
              <ModalOverlay>
                <OverlayContainer>
                  <OverlayText>
                    I was never particularly happy with the starter templates
                    available for <ColorText> ReactJS</ColorText>, so I created
                    one more to my liking. This template quickly gets you
                    started on a new ReactJS project that uses a 'starter'
                    <ColorText> MySQL </ColorText> database and
                    <ColorText> Azure Active Directory B2C </ColorText>
                    tenant for handling users. Just modify a few constants &
                    you're ready to code.
                  </OverlayText>
                  <OverlayButton onClick={toggleModalTwo}>
                    See More
                  </OverlayButton>
                </OverlayContainer>
              </ModalOverlay>
            </ImageContainer>
            <InfoWrapper>
              <ProjectTitle>ReactJS AAD B2C Template</ProjectTitle>
              <LinkWrapper>
                <ProjectLink
                  href="https://github.com/coderpros/balans"
                  target="_blank">
                  <Tooltip text="View Source Code">
                    <StyledCode />
                  </Tooltip>
                </ProjectLink>
              </LinkWrapper>
            </InfoWrapper>
          </SelectionCard>
        </SelectionRow>
        <SelectionRow>
          <SelectionCard>
            <ImageContainer>
              <ProjectImage
                onClick={toggleModalThree}
                style={{ backgroundImage: `url(${buyCoders1})` }}
              />
              <ModalOverlay>
                <OverlayContainer>
                  <OverlayText>
                    BuyCoders is my baby and is almost ready for launch. It is a
                    freelancer marketplace built in{" "}
                    <ColorText>.Net 5 Blazor</ColorText> and also includes a
                    separate messaging system developed with
                    <ColorText> Javascript </ColorText> &{" "}
                    <ColorText> SignalR</ColorText>. Demos <strong>may</strong>{" "}
                    be provided on request.
                  </OverlayText>
                </OverlayContainer>
              </ModalOverlay>
            </ImageContainer>
            <InfoWrapper>
              <ProjectTitle>BuyCoders.com</ProjectTitle>
              <LinkWrapper>
                <ProjectLink href="http://buycoders.com" target="_blank">
                  <Tooltip text="Visit Website">
                    <StyledExternal />
                  </Tooltip>
                </ProjectLink>
              </LinkWrapper>
            </InfoWrapper>
          </SelectionCard>
          <SelectionCard>
            <ImageContainer>
              <ProjectImage
                onClick={toggleModalFour}
                style={{ backgroundImage: `url(${pc1})` }}
              />
              <ModalOverlay>
                <OverlayContainer>
                  <OverlayText>
                    Prospect Competitions is another{" "}
                    <ColorText> raffle </ColorText> website which is super
                    popular in the UK right now. This site and accompanying
                    administrative system was developed in{" "}
                    <ColorText>PHP (Laravel) </ColorText>
                    with a <ColorText> MongoDB </ColorText> backend.
                  </OverlayText>
                  <OverlayButton onClick={toggleModalFour}>
                    See More
                  </OverlayButton>
                </OverlayContainer>
              </ModalOverlay>
            </ImageContainer>
            <InfoWrapper>
              <ProjectTitle>ProspectCompetitions.co.uk</ProjectTitle>
              <LinkWrapper>
                <ProjectLink
                  href="https://prospectcompetitions.co.uk"
                  target="_blank">
                  <Tooltip text="Visit Website">
                    <StyledExternal />
                  </Tooltip>
                </ProjectLink>
              </LinkWrapper>
            </InfoWrapper>
          </SelectionCard>
        </SelectionRow>

        <SelectionRow>
          <SelectionCard>
            <ImageContainer>
              <ProjectImage
                onClick={toggleModalFive}
                style={{ backgroundImage: `url(${sms1})` }}
              />
              <ModalOverlay>
                <OverlayContainer>
                  <OverlayText>
                    <ColorText>ASP.Net MVC 5</ColorText> based system for
                    simplifying the management and reporting for in-store
                    promotions and seasonal change overs. The system also boasts{" "}
                    <ColorText>ad-hoc</ColorText> question & answer components.
                  </OverlayText>
                  <OverlayButton onClick={toggleModalFive}>
                    See More
                  </OverlayButton>
                </OverlayContainer>
              </ModalOverlay>
            </ImageContainer>
            <InfoWrapper>
              <ProjectTitle>
                NTC Marketing ~ In-Store Promo Manager
              </ProjectTitle>
              <LinkWrapper>
                <ProjectLink
                  href="https://ntc-sms.azurewebsites.net"
                  target="_blank">
                  <Tooltip text="Visit Website">
                    <StyledExternal />
                  </Tooltip>
                </ProjectLink>
              </LinkWrapper>
            </InfoWrapper>
          </SelectionCard>

          <SelectionCard>
            <ImageContainer>
              <ProjectImage
                onClick={toggleModalSix}
                style={{ backgroundImage: `url(${d31})` }}
              />
              <ModalOverlay>
                <OverlayContainer>
                  <OverlayText>
                    A macro manager with a licensing system for the super
                    popular game, <ColorText>Diablo 3</ColorText> from Blizzard.
                    Developed in <ColorText>.NET 5 (C#)</ColorText> with a{" "}
                    <ColorText>MongoDB</ColorText> backend. For licensing, I
                    pull the serial number from the user's motherboard in order
                    to control distribution.
                  </OverlayText>
                  <OverlayButton onClick={toggleModalSix}>
                    See More
                  </OverlayButton>
                </OverlayContainer>
              </ModalOverlay>
            </ImageContainer>
            <InfoWrapper>
              <ProjectTitle>Diablo 3 Macro &amp; Licensing System</ProjectTitle>
              <LinkWrapper>
                <ProjectLink
                  href="https://github.com/coderpros/D3MacroLicense"
                  target="_blank">
                  <Tooltip text="View Source Code">
                    <StyledCode />
                  </Tooltip>
                </ProjectLink>
              </LinkWrapper>
            </InfoWrapper>
          </SelectionCard>
        </SelectionRow>
      </SelectionWrapper>
    </Wrapper>
  );
};

export default Projects;
