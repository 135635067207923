import React from "react";
import { Modal } from "react-bootstrap";

import iconImage from "../../images/workstyle/artisan-icon.svg";

class Artisan extends React.Component {
  state = { show: false };

  update = (show) => {
    this.setState({ show });
  };

  handleShow = (show) => {
    this.setState({ show: true });
  };

  handleClose = (show) => {
    this.setState({ show: false });
  };

  render() {
    return (
      <>
        <a
          href="##"
          className="flex mb-4 items-center"
          onClick={this.handleShow}>
          <img src={iconImage} alt="Artisan" />
          <div>
            <h4 className="workStyle-title">artisan</h4>
            <p className="text-white">
              Quality, craft, and attention to detail are the hallmarks of the
              Artisan.
            </p>
          </div>
        </a>

        <WorkStyleDetailModal {...this} title="Artisan" />
      </>
    );

    function WorkStyleDetailModal(props) {
      return (
        <Modal
          show={props.state.show}
          onHide={props.handleClose}
          dialogClassName="modal-xl">
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>
              <h4>{props.title}</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modalBody-container" className="container artisan">
              <div className="row">
                <div className="col-lg-9 col-sm-12">
                  <h3 id="high-level-description">High-level description:</h3>
                  <p>
                    Quality, craft, and attention to detail are the hallmarks of
                    the Artisan. They love a pixel-perfect interface, an
                    intuitive workflow, elegant code, and full test coverage
                    that puts them in their happy place.
                  </p>
                  <h3 id="in-your-ideal-work-environment">
                    In your ideal work environment:
                  </h3>
                  <p>
                    You are extraordinarily wise and discerning. You find your
                    flow state regularly and have confidence in your vision of
                    how you’ll create something great. You’re humane, inspiring,
                    and hopeful. You’re conscientious but also confident in your
                    strong personal convictions. You’re comfortable in your role
                    and find the right balance between being rational,
                    reasonable, self-disciplined, mature, and moderate in all
                    things.
                  </p>
                  <h3 id="in-a-strained-work-environment">
                    In a strained work environment:
                  </h3>
                  <p>
                    You increasingly feel like you have to ‘prove’ why your
                    instincts and decisions are right. You may get the sense
                    that it’s up to you to improve everything in your product or
                    team. Sometimes you feel like you’re the only one who cares
                    about quality as much as speed. You become afraid to make
                    mistakes. You might become overly orderly and
                    well-organized, but lose some personal connection with
                    teammates or feel emotionally constricted.
                  </p>
                </div>
                <div className="col-lg-3 col-sm-12 advice">
                  <h4 className="pt-3 pb-1">Advice</h4>
                  <p>
                    Practice prioritizing. Most artisans have a big dose of
                    perfectionism, which makes them great, but also means it’s
                    sometimes hard to know when to stop and focus on something
                    else. Try breaking down projects into smaller components,
                    then prioritize and timebox the solutions. Ask for feedback!
                    Use a teammate to re-calibrate on what’s important and
                    what’s not. Often you’ll find that others don’t even notice
                    the thing you’re obsessing about. Don’t lose sight of the
                    big picture! Check-in with leadership often to make sure
                    you’re focused on key priorities where the most value is
                    being created.
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
  }
}

export default Artisan;
