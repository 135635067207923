import React from "react";
import { Modal } from "react-bootstrap";

import iconImage from "../../images/workstyle/leader-icon.svg";
import { FaAward } from "react-icons/fa";

class Leader extends React.Component {
  state = { show: false };

  update = (show) => {
    this.setState({ show });
  };

  handleShow = (show) => {
    this.setState({ show: true });
  };

  handleClose = (show) => {
    this.setState({ show: false });
  };

  render() {
    return (
      <>
        <a
          href="##"
          className="flex mb-4 items-center"
          onClick={this.handleShow}>
          <img src={iconImage} alt="Leader" />
          <div>
            <h4 className="workStyle-title text-white">
              <FaAward />
              &nbsp;leader
            </h4>
            <p className="text-white">Leaders see the big picture.</p>
          </div>
        </a>

        <WorkStyleDetailModal {...this} title="Leader" />
      </>
    );

    function WorkStyleDetailModal(props) {
      return (
        <Modal
          show={props.state.show}
          onHide={props.handleClose}
          dialogClassName="modal-xl">
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>
              <h4>{props.title}</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modalBody-container" className="container leader">
              <div className="row">
                <div className="col-lg-9 col-sm-12">
                  <h3 id="high-level-description">High-level description:</h3>
                  <p>
                    Leaders see the big picture. They are vision-setters and
                    sharpeners; they are attentive to teammates; they are
                    mentors and standard-setters.
                  </p>
                  <h3 id="in-your-ideal-work-environment">
                    In your ideal work environment:
                  </h3>
                  <p>
                    You are reliable, hard-working, responsible, and
                    trustworthy. You can foresee problems and you’re a natural
                    leader. You inspire others and are self-confident, strong,
                    and decisive. You like to stay in control of the situation
                    and have enormous willpower to affect positive change and
                    “leave your mark" on every project. You take initiative and
                    make things happen while championing people and providing
                    guidance for members of your team.
                  </p>
                  <h3 id="in-a-strained-work-environment">
                    In a strained work environment:
                  </h3>
                  <p>
                    You often stay overly loyal to ideas, systems, and beliefs —
                    even when it’s not working as intended. You can become
                    resentful when you feel like the success of the team or
                    project is all your responsibility. Your normal
                    vision-setting gives way to what’s safe and stable. You can
                    become detached and start to think too much about organizing
                    and structuring. You may become cautious or procrastinate if
                    you feel like your voice isn’t being heard.
                  </p>
                </div>
                <div className="col-lg-3 col-sm-12 advice">
                  <h4 className="pt-3 pb-1">Advice</h4>
                  <p>
                    Your real power lies in your ability to inspire and uplift
                    people. You are at your best when you take charge and help
                    everyone through challenges but understand when to yield to
                    others, at least occasionally. Stay in the moment. The
                    opportunity to have quality experiences comes by giving your
                    full attention to the experience you are having now. You
                    have a lot to teach others and are likely a good teacher,
                    but do not expect others to change immediately. What is
                    obvious to you may not be as obvious to them, so use
                    patience.
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
  }
}

export default Leader;
