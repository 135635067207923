import React from "react";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import {
  FaJs,
  FaHtml5,
  FaWindows,
  FaDatabase,
  FaCloud,
  FaCss3,
} from "react-icons/fa";

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.background};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15%;
  @media (max-width: 980px) {
    padding: 0 10%;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4em 0;
  flex-wrap: nowrap;
  @media (max-width: 980px) {
    flex-wrap: wrap;
  }
  @media (max-width: 656px) {
    display: none;
  }
`;

const Header = styled.h1`
  color: ${(props) => props.theme.high};
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 1.5em;
  font-family: "Raleway", sans-serif;
  letter-spacing: 2px;
  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 45%;
    padding-top: 0.35em;
    border-bottom: 3px solid white;
  }
  @media (max-width: 656px) {
    margin-bottom: 3rem;
  }
`;

const Card = styled.div`
  width: 12em;
  min-width: 12em;
  height: 18em;
  margin: 0 2em;
  background-color: ${(props) => props.theme.primary};
  transform: scale(0.95);
`;

const IconContainer = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
`;

const CardContent = styled.div`
  padding: 0 1.25em 1.25em;
  background-color: ${(props) => props.theme.elevated};
  width: 100%;
  position: absolute;
  height: 8em;
  bottom: 0;
  z-index: -1;
  transform: scale(1.01);
  clip-path: polygon(0 0, 100% 25%, 100% 100%, 0% 100%);
`;

const CardText = styled.h3`
  margin: 1.7em 0;
  text-align: center;
`;

const CardSubtext = styled.p`
  text-align: center;
  font-size: 0.9rem;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 4em;
  @media (max-width: 656px) {
    flex-direction: column;
  }
`;

const TextPrimary = styled.h2`
  font-size: 2rem;
  text-align: center;
  color: ${(props) => props.theme.high};
  font-style: italic;
  letter-spacing: 1.5px;
  @media (max-width: 1240px) {
    font-size: 1.5rem;
    letter-spacing: 1px;
  }
  @media (max-width: 815px) {
    font-size: 1.1rem;
    letter-spacing: 0.7px;
  }
`;

const TextSecondary = styled.p`
  font-size: 0.9rem;
  margin: 0 1em 2em 1em;
  color: ${(props) => props.theme.medium};
  max-width: 25em;
`;

const ColorText = styled.span`
  color: ${(props) => props.theme.primaryV};
`;

const About = () => {
  return (
    <Wrapper>
      <Header>About</Header>
      <CardWrapper>
        <Card>
          <IconContainer>
            <FaWindows size={70} />
          </IconContainer>
          <CardContent>
            <CardText>.NET 5 / Core</CardText>
            <CardSubtext>Software / Web Development</CardSubtext>
          </CardContent>
        </Card>
        <Card>
          <IconContainer>
            <FaDatabase size={70} />
          </IconContainer>
          <CardContent>
            <CardText>DBA</CardText>
            <CardSubtext>SQL Server / MySQL / MongoDB</CardSubtext>
          </CardContent>
        </Card>
        <Card>
          <IconContainer>
            <FaHtml5 size={70} />
            <FaJs size={70} />
            <FaCss3 size={70} />
          </IconContainer>
          <CardContent>
            <CardText>Client-side</CardText>
            <CardSubtext>Styling / Animation</CardSubtext>
          </CardContent>
        </Card>
        <Card>
          <IconContainer>
            <FaCloud size={70} />
          </IconContainer>
          <CardContent>
            <CardText>Cloud</CardText>
            <CardSubtext>Azure / AWS / DevOps</CardSubtext>
          </CardContent>
        </Card>
      </CardWrapper>
      <InfoWrapper>
        <Fade left>
          <TextPrimary>
            Driven, Self-Motivated, Problem Solver, Critical Thinker.
          </TextPrimary>
        </Fade>
      </InfoWrapper>
      <InfoWrapper>
        <Fade left>
          <TextSecondary>
            I'm an American currently residing in{" "}
            <ColorText>Quito, Ecuador</ColorText>. I've had a passion for
            emerging technologies since I first began my career 20 years ago. I
            was an early adopter of <ColorText>ASP.Net</ColorText> and began
            coding with it with the first beta was released way back in 2000! My
            time on the <ColorText>bleeding edge</ColorText> didn't stop there.
            I'm presently creating solutions with <ColorText>Blazor</ColorText>,
            <ColorText> SignalR</ColorText>, and
            <ColorText> .NET 5</ColorText>!
          </TextSecondary>
          <TextSecondary>
            At this point in my career, I <ColorText>only</ColorText> accept
            projects or jobs that I find exiting or promote for the greater
            good. For instance, I'm presently working on an{" "}
            <ColorText>AI</ColorText> project that enables people to take a
            picture of a skin lesion and it will tell them if it is benign or
            they should seek medical attention.
          </TextSecondary>
          <TextSecondary>
            People tell me that one of the most interesting things about my life
            is that I have been a <ColorText>digital nomad</ColorText> since{" "}
            <ColorText>2009</ColorText>. Over the years, I have lived all over
            the{" "}
            <ColorText>
              United States, Europe, Asia, and South America
            </ColorText>
            . That experience has made me a self-starter to say the least, but
            has given also given me tremendous insight in how to best manage my
            time as well as those under my leadership, not to mention a large
            arsenal of tools for <ColorText>remote working.</ColorText>
          </TextSecondary>
        </Fade>
      </InfoWrapper>
    </Wrapper>
  );
};
export default About;
