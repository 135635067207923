import React from "react";
import styled from "styled-components";

import "../css/certifications.css";
import coldFusionCertificateBadge from "../images/adobe-certified-professional-adobe-coldfusion.png";

const ColorText = styled.span`
  color: ${(props) => props.theme.primary};
`;

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.background};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10%;
  margin-bottom: 6rem;
  @media (max-width: 1200px) {
    padding: 0 5%;
  }
`;

const Header = styled.h1`
  color: ${(props) => props.theme.high};
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 700;
  margin: 1.5rem 0 4rem 0;
  font-family: "Raleway", sans-serif;
  letter-spacing: 2px;
  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 45%;
    padding-top: 0.35em;
    border-bottom: 3px solid white;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  @media (max-width: 790px) {
    flex-direction: column;
  }
`;

const Certifications = () => {
  return (
    <Wrapper>
      <Header>Certifications</Header>
      <ContentWrapper id="certificationContentWrapper">
        <div class="flex-row">
          <div class="flex-column">
            <div class="text-center">
              <p class="text-center">
                <strong>
                  Adobe Certified Developer: <ColorText>ColdFusion</ColorText>
                </strong>
                <br />
                September 2022
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  "https://www.credly.com/badges/0ee0be46-da2b-4d0b-b0b4-d57c41b6a261/public_url"
                }>
                <img
                  src={coldFusionCertificateBadge}
                  alt="Certified in Adobe ColdFusion in August 2022"
                  target="_blank"
                />
              </a>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Certifications;
