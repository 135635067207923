import React from "react";
import { Modal } from "react-bootstrap";

import iconImage from "../../images/workstyle/partner-icon.svg";

class Partner extends React.Component {
  state = { show: false };

  update = (show) => {
    this.setState({ show });
  };

  handleShow = (show) => {
    this.setState({ show: true });
  };

  handleClose = (show) => {
    this.setState({ show: false });
  };

  render() {
    return (
      <>
        <a
          href="##"
          className="flex mb-4 items-center"
          onClick={this.handleShow}>
          <img src={iconImage} alt="Partner" />
          <div>
            <h4 className="workStyle-title">partner</h4>
            <p className="text-white">Partners are master collaborators.</p>
          </div>
        </a>

        <WorkStyleDetailModal {...this} title="Partner" />
      </>
    );

    function WorkStyleDetailModal(props) {
      return (
        <Modal
          show={props.state.show}
          onHide={props.handleClose}
          dialogClassName="modal-xl">
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>
              <h4>{props.title}</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modalBody-container" className="container partner">
              <div className="row">
                <div className="col-lg-9 col-sm-12">
                  <h3 id="high-level-description">High-level description:</h3>
                  <p>
                    Partners are master collaborators. They thrive in
                    environments where ideas are shared and shaped as a group,
                    with ample feedback and quick iteration cycles.
                  </p>
                  <h3 id="in-your-ideal-work-environment">
                    In your ideal work environment:
                  </h3>
                  <p>
                    You are genuinely interested in the experience and
                    perspective of others. Your work yields thoughtful, sincere
                    experiences that make users feel at ease. You are good at
                    unifying team goals and promoting systems that emphasize
                    cooperation and collective success. You help the team
                    achieve and celebrate success, and teammates respond by
                    taking ownership because they feel secure, valued, and
                    trusted.
                  </p>
                  <h3 id="in-a-strained-work-environment">
                    In a strained work environment:
                  </h3>
                  <p>
                    You tend to get overly concerned with your performance and
                    may push yourself unnecessarily hard to achieve impossible
                    goals. You are always pragmatic and efficient but can also
                    become image-conscious, and overly concerned with the
                    expectations and perception of others. When stressed, you
                    avoid collaboration and prefer to go ‘heads-down’, sometimes
                    to your own (or the project’s) detriment.
                  </p>
                </div>
                <div className="col-lg-3 col-sm-12 advice">
                  <h4 className="pt-3 pb-1">Advice</h4>
                  <p>
                    Avoid burnout! Empathy requires a lot of energy. If you have
                    not gotten adequate rest and taken care of yourself properly
                    it will be difficult to do your best work. Take time for
                    yourself. Dive into a personal project or other individual
                    activity to recenter your own talents and interests. Hold
                    high standards for others. Don’t let your desire to help
                    become a crutch for your colleagues. Teaching and
                    questioning is often more beneficial than sharing the
                    workload.
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
  }
}

export default Partner;
